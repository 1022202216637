// Essential for all components
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";

import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";

import moment from "moment";
import {
    getCurrentStateOfPage,
    getPreviousPage,
    getValAfterSlash,
    handleSpecialFieldName
} from "../../utils/PaginationUtils";
import queryString from 'query-string'
import {COMMON_DATE_FORMAT} from "../../constants/common";
import {addMessage} from "../../Redux/Action/messageAction";
import {apiAuction} from "../../Api/_ApiAuction";
import AlertDialog from './AlertDialog';


// Define column names
const getRows = (t) => {
    return [
        {
            id: 'name',
            numeric: false,
            disablePadding: false,
            label: t('AuctionManagement:auctionName'),
            link: '/auction-management/:auction_slot_id',
            customUrlParam: ['auction_slot_id']
        },
        {id: 'source', numeric: true, disablePadding: false, label: t('AuctionManagement:auctionSource')},
        {
            id: 'start_time',
            numeric: false,
            disablePadding: false,
            label: t('AuctionManagement:startTime'),
            type: 'date',
            dateFormat: 'MMM Do YYYY, HH:mm:ss'
        },
        {
            id: 'end_time',
            numeric: false,
            disablePadding: false,
            label: t('AuctionManagement:endTime'),
            type: 'date',
            dateFormat: 'MMM Do YYYY, HH:mm:ss'
        },
        { id: 'lastmoddate', numeric: false, disablePadding: false, label: t('AuctionManagement:updateDate'), type: 'date', dateFormat: 'MMM Do YYYY, HH:mm:ss' }
    ]
}

const rowsPerPageDefault = 10;
const orderByDefault = 'end_time';
const orderDefault = 'desc';

class AuctionManagement extends Component {

    state = {
        data: null,
        //pagination
        amountOfData: '',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault,
        alertDialogOpen: false,
        alertDialogType: null,
        alertDialogCallback: null,
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        let previousPage = values.previous ? getPreviousPage(this.props.currentPaginator, 'auction-management') : null;
        const {currentPage, rowsPerPage, orderBy, order} = getCurrentStateOfPage(previousPage, rowsPerPageDefault, orderByDefault, orderDefault)
        this.setState({
            currentPage: currentPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order
        }, () => {
            this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
        });
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        const {t, i18n} = this.props;
        orderBy = handleSpecialFieldName(orderBy);

        const params =
            {
                is_buy_now: false,
                'supplier/tenant': localStorage.getItem('asTenantId'),
                '$orderby': `${orderBy} ${order}`,
                '$top': rowsPerPage,
                '$skip': skipAmount,
                '$expand': 'supplier',
                '$select': 'auction_slot_id,name,supplier,start_time,end_time,status,lastmoddate'
            };
        console.log('Get auction with param: ', params)

        apiAuction.getAuctions(params).then((rs) => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: t('AuctionManagement:breadcrumb') + ' (' + dataSum + ')',
                                    link: '/' + i18n.language + '/auction-management'
                                }
                            ]
                        }
                    );
                }
                this.setState({
                    data: this.prepareData(rs.data),
                });
            } else {
                console.log("eCb : ", rs);
            }
        });
    }

    prepareData = data => {
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                name: item.status === 'stopped' ? <span style={{ color: '#FF0000' }}>{item.name} (Stopped)</span>: `${item.name}${item.status === 'paused' ? ' (Paused)' : ''}`,
                id: item.auction_slot_id,
                start_time_display: moment(item.start_time).format(COMMON_DATE_FORMAT),
                end_time_display: moment(item.end_time).format(COMMON_DATE_FORMAT),
                update_date: moment(item.lastmoddate).format(COMMON_DATE_FORMAT),
                source: item.supplier.name || '',
            }))
         ) : [];
    };

    _createButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/auction');
    }

    _updateButtonAction = (id) => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/auction/' + id);
    }

    _deleteButtonAction = (ids, rowsPerPage) => {
        const { t } = this.props;
        if (ids && ids.length > 0) {
            return Promise.all(ids.map(id => apiAuction.deleteAuction(id)
                .then(res => {
                    if (res.status === 204) {
                        return;
                    } else {
                        let error = res.data && res.data.error ? res.data.error : t("AuctionManagement:deleteAuctionsFailed");
                        if (error === 'Auction can only be deleted when it is not yet started') {
                            error = t("AuctionManagement:auctionCanOnlyBeDeletedWhenItIsNotYetStarted");
                        }
                        throw new Error(error);
                    }
                }))
            ).then(() => {
                const msgDsp = {
                    messageSnackbar: t("AuctionManagement:deleteAuctionsSuccessfully"),
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
                this._getDataDynamic(0, rowsPerPage, this.state.orderBy, this.state.order, true, null);
            }).catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            });
        }
    }

    _stopButtonAction = (ids, rowsPerPage) => {
        const { t } = this.props;
        const body = {
            status: 'stopped',
        };
        if (ids && ids.length > 0) {
            return Promise.all(ids.map(id => apiAuction.updateAuctionStatus(id, body)
                .then(res => {
                    if (res.status === 200) {
                        return;
                    } else {
                        let error = res.data && res.data.error ? res.data.error : t("AuctionManagement:stopAuctionFailed");
                        if (error === 'Auction cannot be modified after it is ended or stopped') {
                            error = t("AuctionManagement:auctionCannotBeModifiedAfterItIsEndedOrStopped")
                        }
                        throw new Error(error);
                    }
                })
            )).then(() => {
                const msgDsp = {
                    messageSnackbar: t("AuctionManagement:stopAuctionsSuccessfully"),
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
                this._getDataDynamic(0, rowsPerPage, this.state.orderBy, this.state.order, true, null);
            }).catch(err => {
                const msgDsp = {
                    messageSnackbar: err.message,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            });
        }
    }

    render() {
        const { data, amountOfData, currentPage, rowsPerPage, orderBy, order, alertDialogOpen, alertDialogType, alertDialogCallback } = this.state;
        const { t } = this.props;
        return (
            data &&
            <div>
                <BaseListPage
                    pageId={'auction-management'}
                    mainClass={'auction-mgt-page'}
                    primaryKey={'auction_slot_id'}
                    displaySearchBar={false}
                    displayActionBar={true}

                    createButton={true}
                    createButtonText={t('AuctionManagement:newAuction')}
                    createButtonAction={this._createButtonAction}

                    updateButton={true}
                    updateButtonText={t('Common:Button.edit')}
                    updateButtonAction={this._updateButtonAction}

                    deleteButton={true}
                    deleteButtonText={t('Common:Button.delete')}
                    deleteButtonAction={(ids, rowsPerPage) => this.setState({
                        alertDialogOpen: true,
                        alertDialogType: 'delete',
                        alertDialogCallback: () => this._deleteButtonAction(ids, rowsPerPage),
                    })}

                    remainButtons={[{
                        text: t('Common:Button.stop'),
                        selectedOnly: true,
                        className: 'stop-button',
                        onClick: (ids, rowsPerPage) => this.setState({
                            alertDialogOpen: true,
                            alertDialogType: 'stop',
                            alertDialogCallback: () => this._stopButtonAction(ids, rowsPerPage),
                        }),
                    }]}

                    getDataDynamic={this._getDataDynamic}
                    currentPage={currentPage}
                    rows={getRows(t)}
                    data={data}
                    displaySelectBox={true}
                    orderDefault={order}
                    orderByDefault={orderBy}
                    rowsPerPageDefault={rowsPerPage}
                    rowsPerPageOptions={[5, 10, 20]}
                    amountOfData={amountOfData}
                />
                <AlertDialog
                    isOpen={alertDialogOpen}
                    title={t('Common:General.Warning')}
                    onAction={() => {
                        if (typeof alertDialogCallback === 'function') {
                            alertDialogCallback();
                        }
                        this.setState({ alertDialogOpen: false, alertDialogCallback: null });
                    }}
                    onCancel={() => this.setState({ alertDialogOpen: false, alertDialogCallback: null })}
                    actionButtonText={alertDialogType === 'stop' ? t('Common:Button.stop') : t('Common:Button.delete')}
                    cancelButtonText={t('Common:Button.no')}
                    actionButtonClass={alertDialogType === 'stop' ? 'stop-button' : 'delete-button'}
                >
                    {`${t('AuctionManagement:areYouSureYouWantTo')} ${alertDialogType === 'stop' ? t('Common:Button.stop') : t('Common:Button.delete')} ${t('AuctionManagement:theAuctions')}?`}<br />
                    {t('AuctionManagement:youCantUndoThis')}
                </AlertDialog>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    addMessageP: data => dispatch(addMessage(data)),
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data))
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(AuctionManagement)));
