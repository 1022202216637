// Essential for all components
import React, {Component} from 'react';
import {withRouter} from "react-router-dom";
import {withTranslation} from "react-i18next";
import {get, keyBy} from 'lodash-es';
import {saveAs} from 'file-saver';
import Bluebird from 'bluebird';
import JSZip from 'jszip';
// import 'barcode.js';

import BaseListPage from "../../components/101BaseListPage/BaseListPage";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {logout} from '../../Redux/Action/authAction';
import {connect} from "react-redux";
import moment from "moment";
import {
    getCurrentStateOfPage,
    getPreviousPage,
    getValAfterSlash,
    handleSpecialFieldName
} from "../../utils/PaginationUtils";
import queryString from 'query-string'
import { formatCurrency } from '../../utils/Utility';
import {COMMON_DATE_FORMAT} from "../../constants/common";
import {apiClient} from "../../Api/_ApiClient";
import {apiTenant} from "../../Api/_ApiTenant";
import {addMessage} from "../../Redux/Action/messageAction";

var CONCURRENT_QR_CODE_GENERATION = window.navigator.hardwareConcurrency;
if (!Number.isSafeInteger(CONCURRENT_QR_CODE_GENERATION) || CONCURRENT_QR_CODE_GENERATION < 4) {
    CONCURRENT_QR_CODE_GENERATION = 4;
}

function generate_qr_code_image(qrcode) {
    var canvas = window.document.createElement('canvas');
    canvas.width = 200;
    canvas.height = 200;
    window.w69b.qr.encoding.drawOnCanvas(qrcode, canvas, 1);
    return canvas;
}

function get_canvas_blob(canvas) {
    return new Promise(function(resolve, reject) {
        try {
            window.w69b.imgtools.getCanvasAsBlob(canvas, resolve);
        } catch (e) {
            reject(e);
        }
    });
}

async function generate_qrcode(texts, nameWithoutExtArray) {
    if (texts.length > 0) {
        var zip = new JSZip();
        await Bluebird.map(texts, function(text, index) {
            return get_canvas_blob(generate_qr_code_image(text)).then(function(blob) {
                zip.file(nameWithoutExtArray[index] + '.png', blob);
                return null;
            });
        }, {
            concurrency: CONCURRENT_QR_CODE_GENERATION
        });

        var zip_blob = await zip.generateAsync({
            type: 'blob'
        });
        var now = new Date();
        var fileSaver = saveAs(zip_blob, 'qrcode_' + now.toISOString() + '.zip', true);
    }
}

// Define column names
const getRows = (t) => {
    return [
        {
            id: 'display_name',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:displayName"),
            link: '/client/:tenant_member_id',
            customUrlParam: ['tenant_member_id']
        },
        {
            id: 'role_name',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:role")
        },
        {
            id: 'platform',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:platform")
        },
        {
            id: 'qr_code',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:qrCode")
        },
        {
            id: 'url',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:url")
        },
        {
            id: 'scanned',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:scanned")
        },
        {id: 'active_display', numeric: false, disablePadding: false, label: t("TenantMemberManagement:active")},
        {id: 'lastmoddate', numeric: false, disablePadding: false, label: t("TenantMemberManagement:updateDate"), type: 'date'}
    ]
}

const getRows2 = (t) => {
    return [
        {
            id: 'display_name',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:displayName"),
            link: '/client-management/:tenant_member_id',
            customUrlParam: ['tenant_member_id']
        },
        {
            id: 'role_name',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:role")
        },
        {
            id: 'platform',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:platform")
        },
        {
            id: 'username',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:loginName")
        },
        {
            id: 'email',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:email")
        },
        {
            id: 'scanned',
            numeric: false,
            disablePadding: false,
            label: t("TenantMemberManagement:scanned")
        },
        {id: 'wechat_id', numeric: true, disablePadding: false, label: t("TenantMemberManagement:wechat")},
        {id: 'lastmoddate', numeric: false, disablePadding: false, label: t("TenantMemberManagement:updateDate"), type: 'date'}
    ]
}

const rowsPerPageDefault = 3;
const orderByDefault = 'lastmoddate';
const orderDefault = 'desc';

class TenantMemberManagement extends Component {

    state = {
        data: null,
        //pagination
        amountOfData: '',
        currentPage: 0,
        rowsPerPage: rowsPerPageDefault,
        orderBy: orderByDefault,
        order: orderDefault,
        amountOfData2: '',
        currentPage2: 0,
        rowsPerPage2: rowsPerPageDefault,
        orderBy2: orderByDefault,
        order2: orderDefault,
    };

    componentDidMount() {
        const values = queryString.parse(this.props.location.search)
        let previousPage = values.previous ? getPreviousPage(this.props.currentPaginator, 'client-management') : null;
        const {currentPage, rowsPerPage, orderBy, order} = getCurrentStateOfPage(previousPage, rowsPerPageDefault, orderByDefault, orderDefault);
        this.setState({
            currentPage: currentPage,
            rowsPerPage: rowsPerPage,
            orderBy: orderBy,
            order: order,
        }, () => {
            this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
            this._getDataDynamic2(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
        })
    }

    _getDataDynamic = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        const {t, i18n} = this.props;
        orderBy = handleSpecialFieldName(orderBy);

        const params =
            {
                'tenant': localStorage.getItem('asTenantId'),
                '$orderby': `${orderBy} ${order}`,
                '$top': rowsPerPage,
                '$skip': skipAmount,
                '$expand': 'tenant_member_role',
            };

        apiTenant.getTenantMembers(params).then(rs => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData: dataSum});
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: t('TenantMemberManagement:breadcrumb') + ' (' + dataSum + ')',
                                    link: '/' + i18n.language + '/client-management'
                                }
                            ]
                        }
                    );
                }
                this.setState({
                    data: this.prepareData(rs.data),
                });
            } else {
                console.log("eCb : ", rs);
            }
        });
    }

    prepareData = data => {
        const { t } = this.props;
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                id: item.tenant_member_id,
                active_display: item.active ? t("Common:Button.yes") : t("Common:Button.no"),
                update_date: moment(item.lastmoddate).format(COMMON_DATE_FORMAT),
                scanned: item.client ? (item.activated ? t("Common:Button.yes") : t("Common:Button.no") ) : 'Nil',
                role_name: t(`TenantMemberManagement:${get(item, ['tenant_member_role', 'name'], '')}`),
                platform: get(item, ['tenant_member_platforms', 'length'], 0),
            }))
        ) : [];
    };

    _getDataDynamic2 = (skipAmount, rowsPerPage, orderBy, order, isResetNavAndDataAmount, searchSubject) => {
        const {t, i18n} = this.props;
        orderBy = handleSpecialFieldName(orderBy);

        const params =
            {
                'tenant': localStorage.getItem('asTenantId'),
                '$orderby': `${orderBy} ${order}`,
                '$top': rowsPerPage,
                '$skip': skipAmount,
                '$expand': 'tenant_member_role,client/user',
                'client[null]': false,
            };

        apiTenant.getTenantMembers(params).then(rs => {
            if (rs && rs.status === 200) {
                if (isResetNavAndDataAmount) {
                    const dataSum = getValAfterSlash(rs.headers['content-range']);
                    this.setState({amountOfData2: dataSum});
                }
                this.setState({
                    data2: this.prepareData2(rs.data),
                });
            } else {
                console.log("eCb : ", rs);
            }
        });
    }

    prepareData2 = data => {
        const { t } = this.props;
        return (data && data.length > 0) ? (
            data.map(item => ({
                ...item,
                id: item.tenant_member_id,
                active_display: item.active ? t("Common:Button.yes") : t("Common:Button.no"),
                update_date: moment(item.lastmoddate).format(COMMON_DATE_FORMAT),
                scanned: item.client ? (item.activated ? t("Common:Button.yes") : t("Common:Button.no") ) : 'Nil',
                role_name: t(`TenantMemberManagement:${get(item, ['tenant_member_role', 'name'], '')}`),
                platform: get(item, ['tenant_member_platforms', 'length'], 0),
                username: get(item, ['client', 'user', 'username'], ''),
                email: get(item, ['client', 'email'], ''),
                wechat_id: get(item, ['client', 'wechat_id'], ''),
            }))
        ) : [];
    };

    _createButtonAction = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/client');
    }

    _updateButtonAction = (id) => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/client/' + id);
    }

    _deleteButtonAction = (ids) => {
        const { t } = this.props;
        if(ids && ids.length > 0) {
            const idMap = keyBy(ids);
            const deletedUser = this.state.data.filter(item => idMap.hasOwnProperty(item.tenant_member_id)) ;
            const {auth} = this.props;

            // let isSelfDel = deletedUser.findIndex(item => item.user === auth.username);
            Bluebird.map(ids, id => apiTenant.deleteTenantMember(id).then(rs => {
                if (rs.status === 204) {
                    return;
                } else {
                    throw new Error(rs);
                }
            }), {
                concurrency: 4
            })
                .then(() => {
                    const msgDsp = {
                        messageSnackbar: t("TenantMemberManagement:deleteUsersSuccessfully"),
                        variantSnackbar: 'success',
                        key: new Date().getTime(),
                    };
                    this.props.addMessageP(msgDsp);
                    this._getDataDynamic(0, this.state.rowsPerPage, this.state.orderBy, this.state.order, true, null);
                    // if(isSelfDel >= 0){
                    //     setTimeout(this.handleLogout, 2000);
                    // }
                })
                .catch(err => {
                    console.log("eCb : ", err);
                });
        }
    }

    _unjoinButtonAction = (id) => {
        const { t } = this.props;
        const {
            currentPage, rowsPerPage, orderBy, order,
            currentPage2, rowsPerPage2, orderBy2, order2,
        } = this.state;
        apiTenant.updateTenantMember(id, {
            client: null,
            activated: false,
        }).then((obj) => {
            if (obj && obj.status === 200) {
                const msgDsp = {
                    messageSnackbar: t("TenantMemberManagement:unjoinLoginSuccessfully"),
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
                this._getDataDynamic(currentPage * rowsPerPage, rowsPerPage, orderBy, order, true);
                this._getDataDynamic(currentPage2 * rowsPerPage2, rowsPerPage2, orderBy2, order2, true);
            } else {
                const error = obj.data && obj.data.error ? obj.data.error : t("TenantMemberManagement:operationFailed");
                throw new Error(error);
            }
        }).catch(err => {
            const msgDsp = {
                messageSnackbar: err.message,
                variantSnackbar: 'error',
                key: new Date().getTime(),
            };
            this.props.addMessageP(msgDsp);
        });
    }

    _sendPushButtonAction = (id) => {
        console.log('_sendPushButtonAction')
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/send-push-msg/' + id);
    }

    _sendWeChatButtonAction = (id) => {
        console.log('_sendWeChatButtonAction')
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/send-wechat/' + id);
    }

    _sendOneToOneButton = (id) => {
        console.log('_sendOneToOneButton')
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/send-msg/' + id);
    }

    _downloadQrCodeButton = (ids) => {
        const {data} = this.state;
        const idMap = keyBy(ids);
        const selectedData = data.filter(item => idMap.hasOwnProperty(item.id) && item.qr_code);
        generate_qrcode(selectedData.map(item => item.qr_code), selectedData.map(item => item.display_name));
    }

    render() {
        const { t } = this.props;
        const {data, amountOfData, currentPage, rowsPerPage, orderBy, order,
            data2, amountOfData2, currentPage2, rowsPerPage2, orderBy2, order2
        } = this.state;
        return (
            data &&
            <div>
                <BaseListPage
                    pageId={'tenant-member-management'}
                    mainClass={'client-mgt-page'}
                    primaryKey={'tenant_member_id'}
                    displaySearchBar={false}
                    displayActionBar={true}

                    createButton={true}
                    createButtonText={t("TenantMemberManagement:addUser")}
                    createButtonAction={this._createButtonAction}

                    updateButton={true}
                    updateButtonText={t("TenantMemberManagement:editUser")}
                    updateButtonAction={this._updateButtonAction}

                    deleteButton={true}
                    deleteButtonText={t("TenantMemberManagement:deleteUser")}
                    deleteButtonAction={this._deleteButtonAction}

                    sendWeChatButton={true}
                    sendWeChatButtonText={t("TenantMemberManagement:downloadQrCode")}
                    sendWeChatButtonAction={this._downloadQrCodeButton}

                    sendPushButton={true}
                    sendPushButtonText={t("TenantMemberManagement:sendPushToAll")}
                    sendPushButtonAction={this._sendPushButtonAction}

                    sendOneToOneButton={true}
                    sendOneToOneButtonText={t("TenantMemberManagement:sendOne2OneMessageToAll")}
                    sendOneToOneButtonAction={this._sendOneToOneButton}

                    getDataDynamic={this._getDataDynamic}
                    currentPage={currentPage}
                    rows={getRows(t)}
                    data={data}
                    displaySelectBox={true}
                    orderDefault={order}
                    orderByDefault={orderBy}
                    rowsPerPageDefault={rowsPerPage}
                    rowsPerPageOptions={[3, 5, 10, 20]}
                    amountOfData={amountOfData}
                />
                <h2>{t("TenantMemberManagement:joinedLogins")} ({amountOfData2}):</h2>
                {data2 && <BaseListPage
                    pageId={'joined-tenant-member-management'}
                    mainClass={'client-mgt-page'}
                    primaryKey={'tenant_member_id'}
                    displaySearchBar={false}
                    displayActionBar={true}

                    deleteButton={true}
                    deleteButtonText={t("TenantMemberManagement:unjoinLogin")}
                    deleteButtonAction={this._unjoinButtonAction}

                    getDataDynamic={this._getDataDynamic2}
                    currentPage={currentPage2}
                    rows={getRows2(t)}
                    data={data2}
                    displaySelectBox={true}
                    orderDefault={order2}
                    orderByDefault={orderBy2}
                    rowsPerPageDefault={rowsPerPage2}
                    rowsPerPageOptions={[3, 5, 10, 20]}
                    amountOfData={amountOfData2}
                />}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    currentPaginator: state.paginator.currentPaginator
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    logoutP: data => dispatch(logout(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(TenantMemberManagement)));
