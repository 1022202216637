// Essential for all components
import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {withRouter} from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import {Button} from "@material-ui/core";
import {Form, Formik} from "formik";
import ErrorMessage from "../../components/100Include/ErrorMessage";
import moment from "moment";
import * as Yup from "yup";
import {apiConfiguration} from "../../Api/_ApiConfiguration";
import {setBreadcrumb} from "../../Redux/Action/breadcrumbAction";
import {connect} from "react-redux";
import {addMessage} from "../../Redux/Action/messageAction";

class ConfigurationDetail extends Component {

    state = {
        configuration: {
            configuration_id: null,
            description: '',
            fix_value: '',
            lastmoddate: null,
            name: '',
            percentage: '',
            sequence: null,
            type: '',
        },
        defaultValueError: null,
        openSnackbar: false,
        messageSnackbar: '',
        variantSnackbar: '',
    };

    componentDidMount() {
        this.getConfigurationDetail();
    }

    getConfigurationDetail = () => {
        const { t } = this.props;
        if (this.props.id) {
            apiConfiguration.getConfigurationDetail(this.props.platform, this.props.id, {}).then(obj => {
                if (obj && obj.status === 200 && obj.data) {
                    const configItem = obj.data;
                    this.props.setBreadcrumbP(
                        {
                            breadcrumbData: [
                                {
                                    title: `${t("Configuration:configuration")} ${this.props.platform}`,
                                    link: null
                                },
                                {
                                    title: `${t("Common:Button.edit")} [${configItem.name}]`,
                                    link: null
                                }
                            ]
                        }
                    );
                    this.setState({
                        configuration: configItem
                    })
                } else {
                    console.log("eCb : ", obj);
                }
            })
        } else {
            this.props.setBreadcrumbP(null);
        }
    }

    _updateConfigurationAsync = (value) => {
        const { t } = this.props;
        const body = {
            "configuration_id": this.props.id,
            // "sequence": this.state.configuration.sequence,
            // "category": this.state.configuration.category,
            // "type": this.state.configuration.type,
            "fix_value": value.fix_value,
            "percentage": value.percentage,
            // "description": this.state.configuration.description,
            // "name": this.state.configuration.name,
        }

        apiConfiguration.updateConfiguration(this.props.platform, this.props.id, body).then(obj => {
            if (obj.status === 200) {
                const msgDsp = {
                    messageSnackbar: t("Configuration:updateConfigurationSuccessfully"),
                    variantSnackbar: 'success',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
                const {i18n} = this.props;
                this.props.history.push('/' + i18n.language + '/configuration/' + this.props.platform + '/' + this.props.id + '/view');
            } else {
                const msg = obj && obj.data.error ? obj.data.error : t("Configuration:cannotUpdateConfigurationSuccessfully");
                const msgDsp = {
                    messageSnackbar: msg,
                    variantSnackbar: 'error',
                    key: new Date().getTime(),
                };
                this.props.addMessageP(msgDsp);
            }
        });
    }

    cancel = () => {
        const {i18n} = this.props;
        this.props.history.push('/' + i18n.language + '/configuration/' + this.props.platform + '?previous=true');
    }

    handleChange = event => {
        if (event.target.name === 'fix_value') {
            const defaultValue = (event.target.validity.valid) ? event.target.value : this.state.configuration.fix_value;
            this.setState({configuration: {...this.state.configuration, fix_value: defaultValue}});
        } else {
            const value = (event.target.validity.valid) ? event.target.value : this.state.configuration.percentage;
            this.setState({configuration: {...this.state.configuration, percentage: value}});
        }
    };

    formConfiguration = ({ values, errors, touched, handleChange }) => {
        const { t } = this.props;
        return values && (
            <Form id="configurationForm" className=" full-width">
                <Grid container xm={12} alignItems="center" classes={{item: 'form-item'}}>
                    {this.state.MessageContent &&
                    <Grid item xs={12} className="ErrorMessage form-item">
                        <ErrorMessage
                            message={this.state.MessageContent}
                        />
                    </Grid>
                    }

                    <Grid item xs={2} className="form-item">
                        {t("Configuration:parameter")}
                    </Grid>
                    <Grid item xs={10} className="form-item">
                        {values.name}
                    </Grid>

                    <Grid item xs={2} className="form-item pt20">
                        {t("Configuration:description")}
                    </Grid>
                    <Grid item xs={10} className="form-item pt20">
                        {values.description}
                    </Grid>

                    <Grid item xs={2} className="form-item pt20">
                        {t("Configuration:updateDate")}
                    </Grid>
                    <Grid item xs={10} className="form-item pt20">
                        {values.lastmoddate ? moment(values.lastmoddate).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </Grid>
                    <Grid item xs={12} className="form-item pt20">
                        {t("Configuration:defaultValue")} ({t("Configuration:set0ForUnusedOption")})
                    </Grid>
                    <Grid item xs={2} className="form-item pt20">
                        <span className="pl20">{t("Configuration:fixedValue")}</span>
                    </Grid>
                    <Grid item xs={10} className="form-item pt20">
                        <Grid item xs={12} md={6} className="form-item">
                            <input type="text"
                                   name="fix_value"
                                   pattern={this.state.configuration.type === 'int' ? "[0-9.]*" : null}
                                   onChange={this.handleChange}
                                   value={this.state.configuration.fix_value}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={10} className="form-item">
                        {errors.fix_value && touched.fix_value ? <ErrorMessage message={errors.fix_value} /> : null}
                    </Grid>
                    <Grid item xs={2} className="form-item pt10">
                    </Grid>
                    <Grid item xs={10} className="form-item pt10">
                        <span>{t("Configuration:or")}</span>
                    </Grid>
                    <Grid item xs={2} className="form-item pt10">
                        <span className="pl20">{t("Configuration:percentage")}</span>
                    </Grid>
                    <Grid item xs={10} className="form-item pt10">
                        <Grid item xs={12} md={6} className="form-item">
                            <input type="text"
                                   name="percentage"
                                   pattern="[0-9]*\.?[0-9]*"
                                   onChange={this.handleChange}
                                   value={this.state.configuration.percentage}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={10} className="form-item">
                        {errors.percentage && touched.percentage ? <ErrorMessage message={errors.percentage} /> : null}
                    </Grid>
                    <Grid item xs={2} className="form-item">
                    </Grid>
                    <Grid item xs={10} className="form-item">
                        {((touched.fix_value || touched.percentage) && (!values.percentage && !values.fix_value)) || (values.percentage === '0' && values.fix_value === '0') || (values.percentage !== '0' && values.fix_value !== '0')
                            ? <ErrorMessage message={t("Configuration:pleaseSettingDefaultValueAndSet0ForUnusedOption")} /> : null}
                    </Grid>
                    <Grid item xs={12} className="button-wrapper form-item pt20">
                        <Button type="submit" className="primary-button">{t("Common:Button.save")}</Button>
                        <Button type="button" className="second-button" onClick={() => {
                            this.cancel()
                        }}>{t("Common:Button.cancel")}</Button>
                    </Grid>
                </Grid>
            </Form>
        )
    }

    render() {
        const {configuration} = this.state;
        const inputConfiguration = configuration
            ? {...configuration, default_value: '' + configuration.default_value}
            : {
                description: '',
                fix_value: '',
                name: '',
                percentage: '',
            };
        const Schema = Yup.object().shape({
            fix_value: Yup.string(),
            percentage: Yup.string().matches(/^[0-9]+\.?[0-9]*$/, "Percentage is not valid"),
        });

        return (
            <div>
                <div className="main__container flex-center-item">
                    <Formik
                        enableReinitialize
                        initialValues={inputConfiguration}
                        validationSchema={Schema}
                        onSubmit={this._updateConfigurationAsync}
                        component={this.formConfiguration}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});
const mapDispatchToProps = dispatch => ({
    setBreadcrumbP: data => dispatch(setBreadcrumb(data)),
    addMessageP: data => dispatch(addMessage(data)),
});
export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfigurationDetail)));
